import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "punctuation"
    }}>{`Punctuation`}</h1>
    <h2 {...{
      "id": "periods"
    }}>{`Periods`}</h2>
    <p>{`In Workday's UI, a number of factors may complicate where periods are used.`}</p>
    <p>{`Do use periods in the following cases:`}</p>
    <ul>
      <li parentName="ul">{`In running text, at the end of full sentences; for example, when writing body text in an error
message or dialog`}</li>
      <li parentName="ul">{`Most body text in the UI that is a full sentence (if you are in a Figma file, you can select text
and check its style in the right-hand panel)`}</li>
    </ul>
    <p>{`Do not use periods in these cases:`}</p>
    <ul>
      <li parentName="ul">{`Body text that is a fragment.`}</li>
      <li parentName="ul">{`Titles or headers`}</li>
      <li parentName="ul">{`Subtitles or subheaders`}</li>
      <li parentName="ul">{`Any text in title case`}</li>
      <li parentName="ul">{`Any text styled in `}<a parentName="li" {...{
          "href": "/guidelines/content/language-and-style/capitalization"
        }}>{`all caps`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      